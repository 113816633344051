<template>
  <fragment>
    <prk-table
        :custom-fields="CUSTOM_FIELDS"
        :excluded-fields="EXCLUDED_FIELDS"
        :items="items"
        :show-headline="false"
    >
      <template v-slot:table-colgroup="{ fields }">
        <prk-colgroup :columns="['id', 'details']"
                      :fields="fields" width="5%"/>
      </template>

      <template v-slot:head(id)>
        <pcg-checkbox
            :value="checkedAll"
            @input="select('', { deep: true })"
        />
      </template>

      <template v-slot:head(details)/>

      <template #cell(id)="data">
        <syllabus-table-checkbox
            v-model="data.item.selected"
            :data="data" :items="items"
            :select="select"
        />
      </template>

      <template #cell(actions)="{ item: matrix, index }">
        <div class="d-flex justify-content-end align-items-center">
          <syllabus-list-remove-syllabuses
              :matrix="matrix" :index="index"
              @remove:syllabuses="removeAll(index, matrix, $event)"
          />
        </div>
      </template>

      <template #cell(details)="{ item: matrix, detailsShowing }">
        <base-arrow
            :show="detailsShowing"
            @click="matrix._showDetails = !matrix._showDetails"
        />
      </template>

      <template v-slot:row-details="row">
        <syllabuses-list-details
            :key="row.item.id"
            :sub-items="row.item.syllabuses"
            :matrix-id="row.item.id"
            @unChecked="checkedAll = false"
        />
      </template>
    </prk-table>

    <portal to="syllabus-pagination" v-if="items.length > 0">
      <pcg-pagination
          v-if="pagination.totalPages"
          :page-count="pagination.totalPages"
          :total-records="pagination.totalRecords"
          v-model="pagination.currentPage"
          class="mt-4 prk-pagination"
      />
    </portal>

    <objective-effects-modal :matrices="items"/>
    <syllabus-list-comments :syllabusId="syllabusId"/>
    <prk-beam-syllabuses-list
        style="z-index: 99"
        v-if="selectedSyllabuses.length > 0"
        :selected-syllabuses="selectedSyllabuses"
        @remove:syllabuses="removeHandler = $event"
    />
    <confirm-deletion-syllabuses
        :filled-syllabuses="filledSyllabuses"
        @save="removeSyllabuses(true)"
    />
  </fragment>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PrkTable from '../../components/PrkTable'
import SyllabusesListDetails from '../../components/syllabus/syllabuses_list/SyllabusesListDetails'
import ObjectiveEffectsModal from '../../components/syllabus/modals/PrkObjectiveEffectsModal'
import SyllabusTableCheckbox from '../../components/syllabus/syllabuses_table/SyllabusTableCheckbox'
import SyllabusListComments from './SyllabusListComments'
import SyllabusListRemoveSyllabuses from '../../components/syllabus/syllabuses_list/SyllabusListRemoveSyllabuses'
import PrkBeamSyllabusesList from '../../components/beams/PrkBeamSyllabusesList'
import ConfirmDeletionSyllabuses from '../../components/syllabus/modals/ConfirmDeletionSyllabuses'
import PrkColgroup from '../../components/PrkColGroup'
import useSyllabuses from '../../composables/useSyllabuses'
import { detailsTable } from '../../mixins/details_table'
import usePagination from '../../composables/usePagination'

export default {
  name: 'syllabuses-table',
  setup (props, { root }) {
    const {
      categories,
      getCategories,
      getSyllabuses,
      checkedAll,
      getSelected,
      items,
      select,
    } = useSyllabuses(root)
    const { pagination, setPagination } = usePagination(root)
    return {
      categories,
      getCategories,
      getSyllabuses,
      checkedAll,
      getSelected,
      items,
      pagination,
      setPagination,
      select,
    }
  },
  components: {
    PrkColgroup,
    SyllabusListRemoveSyllabuses,
    SyllabusListComments,
    SyllabusTableCheckbox,
    ObjectiveEffectsModal,
    SyllabusesListDetails,
    PrkBeamSyllabusesList,
    ConfirmDeletionSyllabuses,
    PrkTable,
  },
  mixins: [detailsTable],
  data () {
    return {
      componentKey: 0,
      matrices: [],
      removeHandler: null,
      removeHandlers: [],
      filledSyllabuses: [],
      notToFill: false,
      loading: 0,
      pagy: null,
    }
  },
  props: {
    filters: { required: true, type: Object },
    addedCoordinators: Array,
    editedSyllabusIds: Array,
  },
  computed: {
    ...mapState('syllabus', ['syllabusId', 'showWarning']),
    ...mapState('coordinators', ['coordinators']),
    ...mapGetters('auth', ['currentRole', 'userId']),
    selectedSyllabuses () {
      return this.items.map(matrix => matrix.syllabuses).flat()
        .filter(syllabus => syllabus.selected)
    },
    syllabusCoursesTitle () {
      if (this.syllabusId === '') return ''
      const syllabus = this.items.map(matrix => matrix.syllabuses)
        .flat().find(syllabus => syllabus.id === this.syllabusId)
      if (syllabus) {
        return `${syllabus.name}, ${syllabus.type}, ${syllabus.kind}`
      } else {
        return ''
      }
    },
    syllabus () {
      return this.items.map(item => item.syllabuses).flat()
        .find(syllabus => syllabus.id === this.syllabusId)
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler () {
        this.$emit('update:current-page', this.pagination.currentPage)
      },
    },
    addedCoordinators: {
      deep: true,
      handler () {
        if (this.addedCoordinators && this.addedCoordinators.length > 0) {
          this.items.forEach(item => {
            item.syllabuses.forEach(syllabus => {
              syllabus.selected = false
              if (this.editedSyllabusIds.includes(syllabus.id)) {
                this.addedCoordinators.forEach(coordinator => {
                  if (!syllabus.educatorIds.includes(coordinator.value)) {
                    syllabus.educatorIds.push(coordinator.value)
                    const firstName = coordinator.text.split(' ')[0]
                    const lastName = coordinator.text.split(' ')[1]
                    syllabus.educators.push({ id: coordinator.value, firstName: firstName, lastName: lastName })
                    coordinator.roles.forEach(role => {
                      if (role.role === 'coordinator' && role.selected) {
                        if (syllabus.coordinator) {
                          syllabus.coordinator = syllabus.coordinator + '\n' + coordinator.text
                        } else {
                          syllabus.coordinator = coordinator.text
                        }
                      }
                    })
                  } else {
                    coordinator.roles.forEach(role => {
                      if (role.role === 'coordinator' && role.selected && !syllabus.coordinator.includes(coordinator.text)) {
                        if (syllabus.coordinator) {
                          syllabus.coordinator = syllabus.coordinator + '\n' + coordinator.text
                        } else {
                          syllabus.coordinator = coordinator.text
                        }
                      }
                    })
                  }
                })
              }
            })
          })
        }
      },
    },
    items: {
      handler () {
        this.$emit('selected', this.selectedSyllabuses)
        if (this.items && this.items.length > 0) {
          this.setPagination(this.items[0].pagy)
        }
      },
      deep: true,
    },
    filters: {
      async handler (filters) {
        this.loading = 1
        if (this.filters.page && this.filters.page !== this.pagination.currentPage) {
          this.pagination.currentPage = this.filters.page
        }
        const url = `syllabuses?${this.$qs.stringify({ filters: filters })}&paginate=true&page=${this.pagination.currentPage}`
        await this.getSyllabuses(url)
        this.loading = 0
      },
      deep: true,
    },
    loading: {
      handler () {
        if (this.loading === 1) {
          this.$emit('loadingSyllabuses')
        } else {
          this.$emit('stopLoadingSyllabuses')
        }
      },
      deep: true,
    },
    coordinators () {
      this.setCoordinators()
    },
    removeHandler () {
      if (!this.removeHandler) return
      this.removeSyllabuses()
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['_showDetails', 'selected', 'syllabuses', 'pagy']
    this.CUSTOM_FIELDS = [{
      key: 'details',
      label: '',
      tdClass: ['align-middle'],
    }]
    const url = `syllabuses?${this.$qs.stringify({ filters: this.filters })}&paginate=true&page=${this.pagination.currentPage}`

    this.getCategories()
    this.getSyllabuses(url)
  },
  methods: {
    syllabuses (syllabusIds = []) {
      if (!syllabusIds.length) return this.items.map(item => item.syllabuses).flat()
      return this.items.map(item => item.syllabuses).flat()
        .filter(syllabus => syllabusIds.includes(syllabus.id))
    },
    setCoordinators () {
      if (this.coordinators.length) {
        this.syllabuses(this.coordinators[0].syllabusIds).forEach(syllabus => {
          syllabus.coordinator = this.coordinators.map(person => person.text).join('\n')
        })
      } else {
        if (this.syllabus) this.syllabus.coordinator = '-'
        else {
          this.syllabuses(this.selectedSyllabuses.map(syllabus => syllabus.id))
            .forEach(syllabus => { syllabus.coordinator = '-' })
        }
      }
      this.selectedSyllabuses.forEach(syllabus => (syllabus.selected = false))
    },

    showNotToFillInfo (matrix = null) {
      this.notToFill = this.selectedSyllabuses.some(syllabus => syllabus.status !== 'to_fill')
      if (matrix) this.notToFill = matrix.syllabuses.some(syllabus => syllabus.status !== 'to_fill')
      if (!this.notToFill) return
      this.$toastr.w(this.$t('views.syllabus.syllabus_list.status.not_to_fill'))
    },

    async removeAll (index, matrix, event) {
      this.removeHandlers.push(event)
      this.showNotToFillInfo(matrix)
      if (this.notToFill) {
        this.removeHandlers = []
        return
      }
      const res = await this.$removeSwal()
      if (!res.value) return
      for (const removeHandler of this.removeHandlers) {
        removeHandler()
      }
      this.items.splice(index, 1)
    },

    // TODO powinno się zamiast vuexa użyć promise, sprawiłoby to, że kod byłby czytelniejszy i łatwiejszy w utrzymaniu
    async removeSyllabuses (disableSwal = false) {
      const ids = this.selectedSyllabuses.map(syllabus => syllabus.id)
      this.filledSyllabuses = this.selectedSyllabuses.filter(syllabus => syllabus.filled)
      this.showNotToFillInfo()
      if (this.notToFill) return
      if (this.filledSyllabuses.length && this.showWarning) this.$bvModal.show('confirm-deletion-syllabuses')
      else {
        try {
          if (!disableSwal) {
            const res = await this.$removeSwal()
            if (!res.value) return
          }

          await this.removeHandler()
          this.items.forEach((matrix, index) => {
            this.$removeItems(matrix.syllabuses, ids)
            if (!matrix.syllabuses.length) this.items.splice(index, 1)
          })
          this.removeHandler = null
        } catch (e) {
          this.$showError(this.$getErrors(e.errors))
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
