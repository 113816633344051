<template>
  <div class="prk-syllabus-header-container">
    <b-row align-v="center">
      <b-col>
        <b-row>
          <b-col v-if="env && env.classCard" col sm="auto" class="prk-pcg-syllabus-header-title">
            {{ $t('components.prk_syllabus.header.class_card') }}
          </b-col>
          <b-col v-else col sm="auto" class="prk-pcg-syllabus-header-title">
            {{ $t('components.prk_syllabus.header.title') }}
          </b-col>
          <b-col cols="auto">
            <prk-info-type-box
                v-if="showInfoTypeBox"
                :status="status"
            />
            <prk-statuses-select
                v-else-if="status.length"
                :key="reloadPrkStatusesSelect"
                :status="status"
                @input="setStatus"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <div class="prk-pcg-syllabus-header-paragraph">
              {{ duringEducation }}
            </div>
            <div class="prk-pcg-syllabus-header-paragraph">
              {{ $t('components.prk_syllabus.header.training_cycle') }} {{ trainingCycle }}
            </div>
            <div class="prk-pcg-syllabus-header-paragraph">
              {{ $tc('general.academic_year') }} {{ academicYear }}
            </div>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-baseline" v-if="chooseLanguage">
          <b-col cols="auto" class="prk-pcg-syllabus-header-s-paragraph">
            {{ $t('components.prk_syllabus.header.language') }}
          </b-col>
          <b-col cols="auto" class="prk-pcg-p-0">
            <prk-select
                v-model="selectedLanguage"
                class="prk-pcg-syllabus-header-paragraph"
                :options="languageOptions"
                @click="$emit('setLanguage','abs')"
            />
          </b-col>
        </b-row>
      </b-col>

      <slot name="image">
        <b-col cols="12" sm="auto">
          <div class="prk-pcg-syllabus-header-title mb-2">
            {{ $t('components.prk_syllabus.header.logo') }}
          </div>

          <pcg-file-input @input="getImage" :fileUrl="$t('general.file_upload')" :status="fileUploadStatus"/>
        </b-col>
      </slot>
    </b-row>
    <slot name="persons"/>
    <b-row v-if="['during_fill', 'to_fill', 'to_improve'].includes(status)" class="mt-3">
      <pcg-btn variant="additional" @click="checkCourseEffects">
        {{ $t('components.prk_syllabus.header.check_education_effects') }}
      </pcg-btn>
      <pcg-btn variant="additional" @click="$bvModal.show('copy-syllabus')">
        {{ $t('components.prk_syllabus.header.copy_from_previous_years') }}
      </pcg-btn>
    </b-row>
    <view-missing-effects-modal
        :btn-title="$t('components.view_missing_effects_modal.button')"
        modal-id="missing-effects"
        :title-modal="$t('components.view_missing_effects_modal.title')"
        :missing-keks="missingKeks"
    />
  </div>
</template>

<script>
import PrkSelect from '../PrkSelect'
import PrkStatusesSelect from '../PrkStatusesSelect'
import PrkInfoTypeBox from '../PrkInfoTypeBox'
import { inject } from '@vue/composition-api'
import ViewMissingEffectsModal from './modals/ViewMissingEffectsModal'
import { mapGetters } from 'vuex'

export default {
  name: 'prk-main-syllabus-header',
  setup () {
    const keks = inject('keks', [])
    const editMode = inject('editMode', false)
    return {
      keks,
      editMode,
    }
  },
  components: {
    ViewMissingEffectsModal,
    PrkStatusesSelect,
    PrkSelect,
    PrkInfoTypeBox,
  },
  props: {
    emitData: Boolean,
    academicYear: String,
    trainingCycle: String,
    duringEducation: String,
    chooseLanguage: Boolean,
    skipValidation: Boolean,
    language: { type: String, default: 'Polski' },
    status: {
      type: String,
      default: 'template',
    },
    logo: { default: () => ({ url: '' }) },
  },
  data () {
    return {
      selectedLanguage: 'Polski',
      reloadPrkStatusesSelect: 0,
      languageOptions: [
        {
          text: 'Polski',
          value: 'Polski',
        },
        {
          text: 'English',
          value: 'English',
        },
      ],
      missingKeks: [],
    }
  },
  watch: {
    language () {
      this.selectedLanguage = this.languageOptions.find(opt => {
        return opt.value === this.language
      }).value
    },
    emitData () {
      if (!this.emitData) return
      this.$emit('headerData', {
        status: this.status,
        language: this.selectedLanguage,
        logo: this.logo,
      })
    },
  },
  computed: {
    ...mapGetters('environment', ['env']),
    kekIds () {
      return this.keks.map(kek => kek.id)
    },
    currentKekIds () {
      const subjectsEffects = this.$store.getters['syllabusFill/getSubjectEffects']
      return subjectsEffects.map(effect => effect.keks.map(kek => kek.id)).flat()
    },
    fileUploadStatus () {
      return this.logo.url || this.logo.name ? 'success' : 'upload'
    },
    showInfoTypeBox () {
      return this.$checkRouteName('syllabuses_template_edit') ||
          this.$checkRouteName('syllabuses_new_path') ||
          this.$checkRouteName('syllabuses_template_copy') ||
          !this.editMode
    },
  },
  methods: {
    setStatus (event) {
      if (this.skipValidation || this.kekIds.every(id => this.currentKekIds.includes(id))) {
        this.$emit('set-status', event)
      } else {
        this.reloadPrkStatusesSelect += 1
        this.checkMissingCourseEffects()
        this.$toastr.e(this.$t('views.syllabus.header.course_effects_not_assigned'))
      }
    },
    checkCourseEffects () {
      this.missingKeks = []
      if (this.kekIds.every(id => this.currentKekIds.includes(id))) {
        this.$toastr.s(this.$t('views.syllabus.header.all_course_effects_assigned'))
      } else {
        this.checkMissingCourseEffects()
        this.$toastr.e(this.$t('views.syllabus.header.course_effects_not_assigned'))
      }
    },
    checkMissingCourseEffects () {
      this.keks.forEach(kek => {
        const found = this.currentKekIds.find(element => element === kek.id)
        if (!found) {
          this.missingKeks.push(kek)
        }
      })
      this.$bvModal.show('missing-effects')
    },
    getImage (attachment) {
      this.$emit('update:logo', attachment)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/stylesheets/vars.scss';

::v-deep {
  .custom-file-label.pcg-upload {
    overflow: hidden;
  }
}

.prk-syllabus-header-container {
  margin: 0 -60px;
  padding: 32px 60px 53px 64px;
  background-color: $pcg-lightest-gray;
}

.prk-pcg-syllabus-header-title {
  color: $main-color;
  line-height: 2.429rem; // 34px
  font-size: 1.714rem; // 24px
  font-weight: bold;
  letter-spacing: 0.07rem;
}

.prk-pcg-syllabus-header-paragraph, .prk-pcg-syllabus-header-s-paragraph {
  color: $pcg-the-darkest-gray;
}

.prk-pcg-syllabus-header-paragraph {
  font-weight: bold;
  font-size: 1rem; // 14px
  line-height: 1.714rem; // 24px
  margin-bottom: 0.857rem; // 12px
}

.prk-pcg-syllabus-header-s-paragraph {
  font-size: 0.857rem; // 12px;
  line-height: 1.571rem; // 22px
}

/deep/ .filter-option-inner {
  margin-bottom: 0.857rem; // 12px
  color: $main-active-color;
  text-align: center;
}

/deep/ .custom-file {
  max-width: 300px;
  max-height: 135px;
  z-index: 0;
}

/deep/ .custom-file-label {
  border: 2px dashed $main-active-color !important;
  background-color: $pcg-white !important;
  height: 135px !important;
}

.small-select {
  width: 6.9rem;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-syllabus-header-container {
    background-color: $pcg-lightest-gray;
  }

  .prk-pcg-syllabus-header-title {
    color: $main-color;
  }

  .prk-pcg-syllabus-header-paragraph, .prk-pcg-syllabus-header-s-paragraph {
    color: $pcg-the-darkest-gray;
  }

  /deep/ .filter-option-inner {
    color: $main-active-color;
  }

  /deep/ .custom-file-label {
    border: 2px dashed $main-active-color !important;
    background-color: $pcg-white !important;
  }
}
</style>
