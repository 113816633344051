<template>
  <syllabus-box>
    <template #content>
      <b-row>
        <b-col class="d-flex justify-content-center">
          <prk-date
              class="mb-2 mb-lg-0"
              @update:academicYear="updateFilters($event, 'academic_year')"
          />
        </b-col>
      </b-row>
      <b-row class="d-flex flex-wrap">
        <b-col cols="12" lg="6" xl="4"
               v-for="(filter, key) in filteredFilters"
               :key="key"
               class="mb-3">
          <prk-select
              v-if="key !== 'status' && key !== 'subjectName' && key !== 'semesterType'"
              color="aqua"
              :default-option="{ text: $t('general.choose') }"
              :include-blank="true"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
              :options="filter.values"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
          />
          <prk-select
              v-else-if="key === 'semesterType'"
              color="aqua"
              :default-option="{ text: $t('general.choose') }"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
              :options="filter.values"
              @input="updateFilters($event,'semester_type')"
          />
          <pcg-search-select
              v-else-if="key === 'subjectName'"
              :options="filter.values"
              autocomplete="on"
              always-active
              show-label
              v-model="subjectName"
              :placeholder="$t('general.choose')"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
              class="subject-select"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
          />
          <prk-select
              v-else
              class="statuses"
              color="aqua"
              :default-option="filter.values[0]"
              :label="$tc('general.status')"
              :options="filter.values"
              @input="updateFilters($event, 'status')"
          >
            <template v-slot:button-text="{ text, className }">
              <status :status="text" :class-name="className"/>
            </template>
            <template v-slot:item-text="{ option }">
              <status :status="option.text" :class-name="option.class"/>
            </template>
          </prk-select>
        </b-col>
      </b-row>
    </template>
  </syllabus-box>
</template>

<script>
import SyllabusBox from '../PrkSyllabusBox'
import PrkSelect from '../../PrkSelect'
import Status from './syllabuses_list_details/Status'
import PrkDate from '../../PrkDate'
import { getStudyData } from '../../../mixins/getStudyData'
import { mapGetters } from 'vuex'
import { filtersByRole } from '../../../mixins/filters_by_role'

export default {
  name: 'SyllabusesListFilters',
  components: {
    PrkDate,
    Status,
    PrkSelect,
    SyllabusBox,
  },
  mixins: [getStudyData, filtersByRole],
  jsonapi: {
    coordinators: {
      config: {
        method: 'get',
        url: 'educators?coordinators=true',
      },
      update (educators) {
        return educators.map(educator => {
          return {
            text: `${educator.lastName} ${educator.firstName}`,
            value: educator.id,
          }
        })
      },
    },
    subjectNames: {
      config: {
        method: 'get',
        url: 'subject_names?raw=true',
      },
      update (subjectNames) {
        return subjectNames.map(subjectName => {
          return {
            text: subjectName.name,
            value: subjectName.id,
          }
        })
      },
    },
  },
  data () {
    return {
      facultyId: '',
      courseNameId: '',
      coordinators: [],
      subjectNames: [],
      subjectName: '',
    }
  },
  watch: {
    subjectNames () {
      this.filters.subjectName.values = this.subjectNames
    },
    coordinators () {
      this.filters.coordinators.values = this.coordinators
    },
    facultyId (newVal) {
      this.getData(null, ['faculties', 'courses'],
        {
          course_names: { faculty_id: newVal },
        })
      this.filters.courseName.values = this.courseNames
      this.filters.studyProfile.values = this.studyProfiles
      this.filters.speciality.values = this.specialities
    },
    courseNameId (newValue) {
      this.getData(['subject_names', 'specialities'], null, {
        subject_names: { course_name_id: newValue },
        specialities: { course_name_id: newValue },
      })
      this.filters.subjectName.values = this.subjectNames
    },
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    filteredFilters () {
      const filters = Object.fromEntries(Object.entries(this.filters).filter(el => el[1].roles.includes(this.currentRole)))
      filters.subjectName.values.unshift({ text: 'Wybierz', value: null })
      return filters
    },
  },
  async created () {
    await this.getData(['faculties'])
    this.filters.faculty.values = this.faculties
  },
  methods: {
    updateFilters (value, field) {
      if (field === 'subject_name_id') {
        const subjectName = this.subjectNames.find(subjectName => {
          return subjectName.value === value
        })
        if (subjectName.text === 'Wybierz') {
          this.subjectName = ''
          this.$emit('update:filters', null ?? '', 'subject_name')
        } else {
          this.subjectName = subjectName.value
          this.$emit('update:filters', subjectName?.text ?? '', 'subject_name')
        }
      } else {
        this[this.$_.camelCase(field)] = value
        this.$emit('update:filters', value, field)
      }
    },
    text (dropdown) {
      return dropdown.defaultText || dropdown.values[dropdown.selected].text
    },
    select (dropdown, index) {
      dropdown.selected = index
      dropdown.defaultText = ''
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/stylesheets/vars';

::v-deep {
  .syllabus-box.box-container .box {
    box-shadow: none;
    border-radius: 0;
  }

  .pcg-select {
    .dropdown-menu {
      width: 100%;
    }
  }
}

.subject-select {
  ::v-deep .pcg-input::placeholder {
    font-weight: bold;
    color: $main-active-color;
  }
  ::v-deep .pcg-input {
    font-weight: bold;
    color: $main-active-color;
  }
}
</style>
