import { computed, reactive, provide, ref } from '@vue/composition-api'
import { langProvider } from './useSyllabusesShared'
import useSyllabusHeader from './useSyllabusHeader'
import useCategories from './useCategories'
import usePrepareSyllabus from './usePrepareSyllabus'
import useSyllabusRelatedCollections from './useSyllabusRelatedCollections'

export default function (root) {
  const { getTrainingCycle } = useSyllabusHeader()
  const { categories } = useCategories(root)
  const { prepareSyllabus } = usePrepareSyllabus(root)
  const {
    mapEducators,
    mapKeks,
    // mapLecturers,
    mapSections,
    mapSemesters,
    setDidacticMethodsToVuex,
    setLeadersToVuex,
    setLiteraturesToVuex,
    setSubjectEffectsToVuex,
    setTeachingEffectsToVuex,
    setWorkloadToVuex,
  } = useSyllabusRelatedCollections(root)

  const syllabus = reactive({
    id: '',
    header: {
      status: '',
      academicYear: '',
      trainingCycle: '',
      duringEducation: '',
      logoUrl: '',
      skipValidation: '',
    },
    language: computed(() => root.$store.state.syllabusFill.language),
    matrixSubjectNameId: '',
    subjectNameId: '',
    faculty: '',
    course: '',
    courseId: '',
    subject: '',
    kind: '',
    kindId: '',
    type: '',
    studyProfile: '',
    speciality: '',
    specialityId: '',
    sections: [],
    semesters: [],
    educators: [],
    keks: [],
    educatorIds: [],
    literatures: computed(() => root.$store.getters['syllabusFill/getLiteratures']),
    workWithTeacher: computed(() => root.$store.getters['syllabusFill/getWorkWithTeacher']),
    ownWork: computed(() => root.$store.getters['syllabusFill/getOwnWork']),
    didacticMethods: computed(() => root.$store.getters['syllabusFill/getDidacticMethods']),
    subjectEffects: computed(() => root.$store.getters['syllabusFill/getSubjectEffects']),
    leaders: computed(() => root.$store.getters['syllabusFill/getLeaders']),
    comments: computed(() => root.$store.state.comments.difference),
    currentUserId: computed(() => root.$store.getters['auth/userId']),
    role: computed(() => root.$store.getters['auth/currentRole']),
  })

  const permissionPaths = ref([])

  const getSyllabus = async (id) => {
    const response = await root.$api.get(`syllabuses/${id}`)
    const syllabusData = response.data
    const matrixAttributes = response.included.filter(data => data.type === 'matrix')[0].attributes
    const sectionsData = response.included.filter(data => data.type === 'syllabus_section')
    syllabus.id = syllabusData.id
    syllabus.header.status = syllabusData.attributes.status
    syllabus.header.academicYear = syllabusData.attributes.academicYear
    syllabus.header.logoUrl = syllabusData.attributes.logo?.url
    syllabus.subject = syllabusData.attributes.matrixSubjectNameName
    syllabus.matrixSubjectNameId = syllabusData.attributes.matrixSubjectNameId
    syllabus.type = syllabusData.attributes.studyPlan.data.attributes.type
    syllabus.faculty = syllabusData.attributes.faculty
    syllabus.speciality = syllabusData.attributes.specialityName
    syllabus.specialityId = syllabusData.attributes.specialityId
    syllabus.kind = syllabusData.attributes.studyPlan.data.attributes.kind
    syllabus.kindId = syllabusData.attributes.studyPlan.data.attributes.kindId
    syllabus.course = syllabusData.attributes.studyPlan.data.attributes.courseName
    syllabus.courseId = matrixAttributes.courseId
    syllabus.subjectNameId = syllabusData.attributes.subjectNameId
    syllabus.studyProfile = matrixAttributes.studyProfile
    syllabus.semesters = mapSemesters(response.included.filter(data => data.type === 'semester'))
    syllabus.header.duringEducation = _duringEducation()
    syllabus.sections = mapSections(sectionsData)
    syllabus.keks = mapKeks(response.included.filter(data => data.type === 'matrix_subject_name_effect'))
    syllabus.educators = mapEducators(response.included.filter(data => data.type === 'educator'))
    syllabus.educatorIds = syllabusData.attributes.educatorIds
    syllabus.header.skipValidation = syllabusData.attributes.skipValidation
    root.$store.dispatch('syllabusFill/setLanguage', syllabusData.attributes.language)
    _mapBasicInformationRows(response.included.filter(data => data.type === 'syllabus_basic_information_row'))
    _mapElementSubsections(response.included.filter(data => data.type === 'syllabus_element_subsection'))
    const semesterCount = response.data.attributes.semesterCountBySemesterNumber
    _setSemesterYears(semesterCount)
    setLeadersToVuex(response.included.filter(data => data.type === 'leader'))
    await setTeachingEffectsToVuex()
    setSubjectEffectsToVuex(response.included.filter(data => data.type === 'subject_effect'))
    setDidacticMethodsToVuex(response.included.filter(data => data.type === 'didactic_method'), formOfClasses)
    setLiteraturesToVuex(response.included.filter(data => data.type === 'syllabus_literature'))
    setWorkloadToVuex(syllabusData.attributes.ownWork, syllabusData.attributes.workWithTeacher, syllabus.semesters)
    // mapLecturers(response.included.filter(data => data.type === 'semester'))
    await _fetchPermissionPaths()
    syllabus.sections[0].basicInformationId = sectionsData[0].relationships.syllabusBasicInformation.data.id
  }

  const clearSyllabus = () => {
    syllabus.sections[0].rows.filter(row => {
      return ['input', 'textarea', 'autocomplete'].includes(row.elementType)
    }).forEach(row => (row.value = ''))
    syllabus.sections.map(section => section.elements).flat()
      .forEach(element => {
        switch (element.elementType) {
          case 'checkbox':
            element.value.value = false
            break
          case 'one_line':
            element.value.value = ''
            break
          case 'text_field':
            element.value.value = ''
            break
          case 'table':
            element.value.value.slice(1).forEach(el => {
              for (const key in el) {
                el[key] = ''
              }
            })
            element.value.value.splice(2)
            break
        }
      })
    syllabus.sections.forEach(section => {
      section.comments = []
      section.commentCount = 0
    })
    syllabus.didacticMethods.forEach(method => (method.text = ''))
    root.$store.dispatch('syllabusFill/resetState')
  }
  // PROVIDERS ---------------------------------------------------------------------------------
  const formOfClasses = computed(() => {
    return root.$_.uniqWith(syllabus.semesters.map(semester => semester.subjectForms
      .map(form => {
        return {
          value: form.formOfClassId,
          text: form.formOfClassName,
        }
      },
      )).flat(), root.$_.isEqual)
  })
  provide('semesters', computed(() => syllabus.semesters))
  provide('educators', computed(() => syllabus.educators))
  provide('keks', computed(() => syllabus.keks))
  provide('kens', computed(() => syllabus.kens))
  provide('hasTeachingEffects', computed(() => syllabus.hasTeachingEffect))
  provide('status', computed(() => syllabus.header.status))
  provide('statusesDataCompleted', ['to_verification', 'accepted'])
  provide('categories', computed(() => categories.value))
  provide('matrixSubjectNameId', computed(() => syllabus.matrixSubjectNameId))
  provide('editMode', computed(() => (syllabus.educatorIds.includes(syllabus.currentUserId) &&
          checkPermission.value) || ['admin', 'employer'].includes(syllabus.role)))
  langProvider()

  // PRIVATE METHODS------------------------------------------------------------------

  const _fetchPermissionPaths = async () => {
    const { data: paths } = await root.$api.get('permission_paths')
    paths.map(path => (permissionPaths.value.push({ id: path.id, ...path.attributes })))
  }

  const checkPermission = computed(() => {
    const educatorRoles = [...new Set(syllabus.educators
      .filter(edu => edu.id === syllabus.currentUserId)
      .map(edu => edu.roles)
      .flat())]

    const allowedRoles = [...new Set(permissionPaths.value
      .filter(path => path.from === syllabus.header.status)
      .flat()
      .map(path => path.roles)
      .flat())]

    return allowedRoles.some(a => educatorRoles.includes(a))
  })

  const _duringEducation = () => {
    return `${syllabus.subject}, ${syllabus.type}, ${syllabus.kind}, ${syllabus.semesters.map(s => s.semester)}`
  }
  const _setSemesterYears = (semesterCount) => {
    const academicYear = syllabus.header.academicYear
    syllabus.header.trainingCycle = getTrainingCycle(academicYear, semesterCount)
  }

  const _mapBasicInformationRows = (rows) => {
    rows.forEach(row => {
      syllabus.sections[0].rows.push({
        id: row.id,
        ...row.attributes,
      })
    })
  }
  const _mapElementSubsections = (elements) => {
    elements.forEach(element => {
      if (!element.attributes.elementType) return
      const sectionId = element.relationships.syllabusSection.data.id
      syllabus.sections.find(section => section.id === sectionId).elements.push({
        id: element.id,
        descriptionAbove: element.attributes.descriptionAbove,
        descriptionUnder: element.attributes.descriptionUnder,
        elementType: element.attributes.elementType,
        label: element.attributes.label,
        value: element.attributes.value,
        visible: element.attributes.visible,
        position: element.attributes.position,
      })
    })
  }

  return {
    getSyllabus,
    syllabus,
    prepareSyllabus,
    clearSyllabus,
  }
}
