import useList from './useList'
import useSyllabusCard from './useSyllabusCard'
import { provide, ref } from '@vue/composition-api'

export default function (root) {
  const {
    setItems,
    items,
    checkedAll,
    getSelected,
    select,
    removeRow,
  } = useList(root)
  const categories = ref([])
  const { makeSyllabusCardUniq } = useSyllabusCard(root)

  const getSyllabuses = async (url, params) => {
    const matricesData = await root.$api.get(url, params)
    root.matrices = matricesData.data.map(matrix => {
      return {
        id: matrix.id,
        faculty: matrix.attributes.faculty,
        course: matrix.attributes.courseName,
        type: matrix.attributes.type,
        form: matrix.attributes.kind,
        studyProfile: matrix.attributes.studyProfile,
        speciality: matrix.attributes.speciality,
        selected: false,
        _showDetails: false,
        syllabuses: [],
        pagy: matricesData,
      }
    })
    const syllabusesData = matricesData.included.filter(data => data.type === 'syllabus')
    const effects = matricesData.included.filter(data => data.type === 'subject_effect')
    const educators = matricesData.included.filter(data => data.type === 'educator')
    _mapSyllabuses(syllabusesData, educators)
    _mapSubjectEffects(effects)
    root.matrices.forEach(matrix => matrix.syllabuses.sort((a, b) => a.name.localeCompare(b.name)))
    setItems(root.matrices.filter(matrix => matrix.syllabuses.length > 0))
  }

  const getCategories = async () => {
    const { data: categoriesData } = await root.$api.get('categories')
    categories.value = categoriesData.map(category => {
      return {
        id: category.id,
        name: category.attributes.name,
      }
    })
  }

  const _mapSyllabuses = (syllabusesData) => {
    let matrix = null
    syllabusesData = root.$_.groupBy(syllabusesData, (syllabusData) => {
      return syllabusData.attributes.matrixId
    })

    for (const matrixId in syllabusesData) {
      matrix = root.matrices.find(matrix => matrix.id === matrixId)
      const syllabusesWithUpdatedName = makeSyllabusCardUniq(syllabusesData[matrixId])
      syllabusesWithUpdatedName.forEach(syllabusData => {
        const syllabus = {
          id: syllabusData.id,
          name: syllabusData.syllabusCard,
          coordinator: syllabusData.coordinators.length
            ? syllabusData.coordinators.join('\n') : '-',
          kind: syllabusData.kind,
          type: syllabusData.type,
          specialityName: syllabusData.specialityName,
          status: syllabusData.status,
          commentsCount: syllabusData.commentsCount,
          matrixSubjectNameId: syllabusData.matrixSubjectNameId,
          educatorIds: syllabusData.educators.map(educator => educator.id),
          educators: syllabusData.educators.map(educator => {
            return {
              id: educator.id,
              firstName: educator.firstName,
              lastName: educator.lastName,
              email: educator.email,
            }
          }),
          filled: syllabusData.filled,
          selected: false,
          effects: [],
          matrixId: syllabusData.matrixId,
          acceptanceDateTime: syllabusData.acceptanceDateTime,
          semesterNumber: syllabusData.semesterNumber,
        }
        matrix.syllabuses.push(syllabus)
      })
    }
  }

  const _mapSubjectEffects = (effectsData) => {
    effectsData.forEach(effect => {
      const syllabusId = effect.attributes.syllabusId
      const syllabuses = root.matrices.map(matrix => matrix.syllabuses).flat()
      const syllabus = syllabuses.find(syllabus => syllabus.id === syllabusId)
      if (syllabus) {
        syllabus.effects.push({
          id: effect.attributes.id,
          educationalOutcome: effect.attributes.symbol,
          effectContentObj: effect.attributes.description,
          keks: effect.attributes.keks,
          kens: effect.attributes.kens,
          categoryId: effect.attributes.categoryId,
        })
      }
    })
  }

  const setStatus = async (payload = { id: '', matrixId: '', newStatus: '' }) => {
    try {
      await root.$api.post('syllabuses/set_status', {
        id: payload.id,
        status: payload.newStatus,
      })
      _changeStatus(payload.id, payload.matrixId, payload.newStatus)
    } catch (e) {
      root.$toastr.e(e.errors.messages)
      if (e.errors.emailError) {
        _changeStatus(payload.id, payload.matrixId, payload.newStatus)
      }
    }
  }

  const _changeStatus = (id, matrixId, newStatus) => {
    const matrix = root.matrices.find(matrix => matrix.id === matrixId)
    const syllabus = matrix.syllabuses.find(syllabus => syllabus.id === id)
    syllabus.status = newStatus
  }

  provide('categories', categories)

  return {
    checkedAll,
    getCategories,
    getSelected,
    getSyllabuses,
    items,
    removeRow,
    select,
    setStatus,
  }
}
