import Vue from 'vue'
import VueRouter from 'vue-router'
import { app } from '../index'
import { store } from '../store/index'
import { i18n } from '../translations/index'
import _ from 'lodash'
import qs from 'qs'
import VueI18n from 'vue-i18n'

import Login from '../views/Login'
import DevSessionsIndex from '../views/dev_sessions/DevSessionsIndex'

import Syllabuses from '../views/Syllabuses'
import SyllabusShow from '../views/syllabus/SyllabusShow'
import SyllabusesList from '../views/syllabus/SyllabusesList'
import SyllabusForm from '../views/syllabus/SyllabusForm'
import TemplatesList from '../views/syllabus/TemplatesList'
import SyllabusEdit from '../views/syllabus/SyllabusEdit'

import Matrices from '../views/Matrices'
import MatricesList from '../views/matrix/MatricesList'
import MatricesEdit from '../views/matrix/MatricesEdit'

import Courses from '../views/Courses'
import CourseForm from '../views/courses/CourseForm'
import CoursesList from '../views/courses/CoursesList'

import StudyPlans from '../views/StudyPlans'
import StudyPlansList from '../views/study_plan/StudyPlansList'
import StudyPlanForm from '../views/study_plan/StudyPlanForm'

import Dictionary from '../views/Dictionary'
import DictionaryCategories from '../views/dictionary/DictionaryCategories'

import DictionaryFields from '../views/dictionary/DictionaryFields'
import DictionaryDisciplines from '../views/dictionary/DictionaryDisciplines'
import DictionaryTeachingEffects
  from '../views/dictionary/DictionaryTeachingEffects'
import DictionaryStandardEffects from '../views/dictionary/DictionaryStandardEffects'
import DictionaryGeneralEffects
  from '../views/dictionary/DictionaryGeneralEffects'

import DictionaryCharacteristicEffects from '../views/dictionary/DictionaryCharacteristicEffects'

import DictionaryStandards
  from '../views/dictionary/Standards/DictionaryStandards'
import DictionaryStandardForm from '../views/dictionary/Standards/DictionaryStandardForm'
import DictionaryFaculties from '../views/dictionary/DictionaryFaculties'
import DictionaryCourses from '../views/dictionary/DictionaryCourses'
import DictionaryTypes from '../views/dictionary/DictionaryTypes'
import DictionaryKinds from '../views/dictionary/DictionaryKinds'
import DictionaryProfiles from '../views/dictionary/DictionaryProfiles'
import DictionarySubjectNames from '../views/dictionary/DictionarySubjectNames'
import DictionaryFormOfClasses from '../views/dictionary/DictionaryFormOfClasses'
import DictionaryFormOfCredits from '../views/dictionary/DictionaryFormOfCredits'
import DictionaryCourseEffects from '../views/dictionary/DictionaryCourseEffects'
import DictionaryLiteratures from '../views/dictionary/DictionaryLiteratures'

import Personnel from '../views/Personnel'
import PersonnelList from '../views/personnel/PersonnelList'
import Permissions from '../views/Permissions'
import PermissionList from '../views/permissions/PermissionList'
import DictionarySpecialities from '../views/dictionary/DictionarySpecialities'

Vue.use(VueRouter)
Vue.use(VueI18n)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'exact-active',
  routes: [
    {
      path: '/',
      name: 'root_path',
      component: Login,
    },
    {
      path: '/login',
      name: 'login_path',
      component: Login,
    },
    {
      path: '/openid-connect',
      name: 'openid_login_path',
      component: Login,
    },
    {
      path: '/dev_login',
      name: 'dev_sessions_path',
      component: DevSessionsIndex,
      meta: { env: 'development' },
    },
    {
      path: '/syllabuses',
      component: Syllabuses,
      meta: { auth: true },
      children: [
        {
          path: 'templates_list',
          name: 'syllabuses_templates_list_path',
          component: TemplatesList,
          beforeEnter: (to, from, next) => {
            if (store.getters['auth/currentRole'] !== 'admin') next({ name: 'syllabuses_list_path' })
            else next()
          },
        },
        {
          path: 'new',
          name: 'syllabuses_new_path',
          component: SyllabusForm,
        },
        {
          path: ':id/edit',
          name: 'syllabuses_edit_path',
          component: SyllabusEdit,
        },
        {
          path: 'list',
          name: 'syllabuses_list_path',
          component: SyllabusesList,
        },
        {
          path: 'preview',
          name: 'syllabuses_preview_path',
          component: SyllabusShow,
        },
        {
          path: ':id/template_edit',
          name: 'syllabuses_template_edit',
          component: SyllabusForm,
        },
        {
          path: ':id/template_copy',
          name: 'syllabuses_template_copy',
          component: SyllabusForm,
        },
      ],
    },
    {
      path: '/matrices',
      component: Matrices,
      meta: { auth: true },
      children: [
        {
          path: 'list',
          name: 'matrices_list_path',
          component: MatricesList,
        },
        {
          path: ':id/edit',
          name: 'matrix_edit_path',
          component: MatricesEdit,
        },
      ],
    },
    {
      path: '/dictionaries',
      component: Dictionary,
      meta: { auth: true },
      children: [
        {
          path: 'fields',
          name: 'dictionaries_fields_path',
          component: DictionaryFields,
        },
        {
          path: 'categories',
          name: 'dictionaries_categories_path',
          component: DictionaryCategories,
        },
        {
          path: 'literatures',
          name: 'dictionaries_literatures_path',
          component: DictionaryLiteratures,
        },
        {
          path: 'course_effects',
          name: 'dictionary_course_effects_path',
          component: DictionaryCourseEffects,
        },
        {
          path: 'general_effects',
          name: 'dictionaries_general_effects_path',
          component: DictionaryGeneralEffects,
        },
        {
          path: 'teaching_effects',
          name: 'dictionaries_teaching_effects_path',
          component: DictionaryTeachingEffects,
        },
        {
          path: 'standard_effects',
          name: 'dictionaries_standard_effects_path',
          component: DictionaryStandardEffects,
        },
        {
          path: 'disciplines/:fieldId',
          name: 'dictionaries_disciplines_path',
          component: DictionaryDisciplines,
        },
        {
          path: 'characteristic_effects/:generalEffectId',
          name: 'dictionaries_characteristic_effects_path',
          component: DictionaryCharacteristicEffects,
        },
        {
          path: 'standards',
          name: 'dictionaries_standards_path',
          component: DictionaryStandards,
        },
        {
          path: 'standards/new',
          name: 'dictionaries_standards_new_path',
          component: DictionaryStandardForm,
        },
        {
          path: 'standards/:id/edit',
          name: 'standard_edit_path',
          component: DictionaryStandardForm,
        },
        {
          path: 'faculties',
          name: 'dictionaries_faculties_path',
          component: DictionaryFaculties,
        },
        {
          path: 'faculties/:facultyId/courses',
          name: 'dictionaries_courses_path',
          component: DictionaryCourses,
        },
        {
          path: 'specialities',
          name: 'dictionaries_specialities_path',
          component: DictionarySpecialities,
        },
        {
          path: 'courses/:courseNameId/specialities',
          name: 'dictionaries_course_specialities_path',
          component: DictionarySpecialities,
        },
        {
          path: 'types',
          name: 'dictionaries_types_path',
          component: DictionaryTypes,
        },
        {
          path: 'kinds',
          name: 'dictionaries_kinds_path',
          component: DictionaryKinds,
        },
        {
          path: 'profiles',
          name: 'dictionaries_profiles_path',
          component: DictionaryProfiles,
        },
        {
          path: 'subject_names',
          name: 'dictionaries_subject_names_path',
          component: DictionarySubjectNames,
        },
        {
          path: 'form_of_classes',
          name: 'dictionaries_form_of_classes_path',
          component: DictionaryFormOfClasses,
        },
        {
          path: 'form_of_credits',
          name: 'dictionaries_form_of_credits_path',
          component: DictionaryFormOfCredits,
        },
      ],
      beforeEnter: (to, from, next) => {
        if (['admin'].includes(store.getters['auth/currentRole'])) {
          next()
        } else next({ name: 'syllabuses_list_path' })
      },
    },
    {
      path: '/courses',
      component: Courses,
      meta: { auth: true },
      children: [
        {
          path: 'list',
          name: 'courses_list_path',
          component: CoursesList,
        },
        {
          path: 'new',
          name: 'courses_new_path',
          component: CourseForm,
        },
        {
          path: ':id/edit',
          name: 'courses_edit_path',
          component: CourseForm,
        },
      ],
    },
    {
      path: '/study_plans',
      component: StudyPlans,
      meta: { auth: true },
      children: [
        {
          path: 'list',
          name: 'study_plans_list_path',
          component: StudyPlansList,
        },
        {
          path: 'new',
          name: 'study_plans_new_path',
          component: StudyPlanForm,
        },
        {
          path: 'edit',
          name: 'study_plans_edit_path',
          component: StudyPlanForm,
        },
      ],
    },
    {
      path: '/personnel',
      component: Personnel,
      meta: { auth: true },
      children: [
        {
          path: 'list',
          name: 'personnel_list_path',
          component: PersonnelList,
        },
      ],
    },
    {
      path: '/permissions',
      component: Permissions,
      meta: { auth: true },
      children: [
        {
          path: 'list',
          name: 'permission_list_path',
          component: PermissionList,
        },
      ],
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/currentRole'] !== 'admin') next({ name: 'syllabuses_list_path' })
        else next()
      },
    },
  ],
  parseQuery (query) {
    return qs.parse(query)
  },

  stringifyQuery (query) {
    const result = qs.stringify(query, { arrayFormat: 'brackets' })
    return result ? ('?' + result) : ''
  },
})

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    store.dispatch('header/clearHeader')
    store.dispatch('pageCover/clearBackgroundImage')
    store.dispatch('pageCover/clearBackgroundImageStyle')
    store.dispatch('pageCover/clearBackgroundImageClasses')
    store.dispatch('sidebarMenu/clearSidebarMenuClasses')
    store.dispatch('footerNavbar/clearItems')

    setTimeout(() => {
      const navigationPath = _.compact(to.matched.slice().reverse().map(route => {
        const translationPath = route.meta.navigationNameTranslationPath

        if (Array.isArray(translationPath)) {
          return translationPath.map(path => app ? app.$t(path) : i18n.t(path)).join(' | ')
        }
        return app ? app.$t(translationPath) : i18n.t(translationPath)
      }))
      store.dispatch('header/clearObjectName')
      store.dispatch('header/setNavigationPath', navigationPath)
    }, 0)

    const userRoles = store.getters['auth/roles']

    if (
      store.getters['auth/signedIn'] &&
      store.getters['auth/currentRole'] &&
      userRoles && userRoles.length > 0 &&
      !['root_path', 'login_path', 'role_choose_path', 'openid_login_path'].includes(to.name)
    ) {
      store.dispatch('notifications/getAllNotifications')
    }
  }

  if (to && to.meta.env) {
    if (process.env.RAILS_ENV === to.meta.env) {
      return next()
    } else {
      return next({ name: 'login_path' })
    }
  }

  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/signedIn']) {
      if (to.meta.roles) {
        const userRole = store.getters['auth/currentRole']
        if (to.meta.roles.includes(userRole)) {
          next()
        } else {
          next({ name: 'root_path' })
        }
      } else {
        next()
      }
    } else {
      const redirectTo = {
        name: to.name,
        params: to.params,
        query: to.query,
      }
      store.dispatch('auth/setRedirectTo', redirectTo)
      next({ name: 'login_path' })
    }
  } else {
    const userRole = store.getters['auth/currentRole']
    if (store.getters['auth/signedIn'] && ['login_path', 'root_path'].includes(to.name)) {
      next({ name: 'pcg_components_path' })
    }
    next()
  }
})

export default router
