<template>
  <div>
    <template v-if="header.key === 'information'" class="font-weight-bold">
      <div class="d-flex align-items-center font-weight-bold">
        {{ label(data.value) }}
        <div
            v-if="data.index === 0 && header.key === 'information'"
            v-b-popover.hover.top="`${$t('components.prk_syllabus.table.course_code_info')}`"
            class="question mx-3 font-weight-bold">
          ?
        </div>
      </div>
    </template>
    <template v-if="header.key === 'value'">
      <div v-if="data.item.information === 'language'">
        {{ Number(data.item.value) === 0 ? 'Polski' : 'English' }}
      </div>
      <div v-else-if="data.item.information === 'leading_persons'">
        <prk-tag :value="leaders" :without-remove="true"/>
      </div>
      <div v-else v-html="data.item.value">
      </div>
    </template>
    <template v-if="header.key === 'actions' && ( status === 'to_verification' || status === 'to_improve')">
      <!--      <div class="d-flex align-items-center">-->
      <!--        <base-button v-if="data.item.isEditable" text="edit_row" class="mr-3">-->
      <!--          <base-icon width="15" height="19">-->
      <!--            <icon-edit/>-->
      <!--          </base-icon>-->
      <!--        </base-button>-->
      <!--        <base-button-->
      <!--            v-if="data.item.comments.length"-->
      <!--            text="edit_row"-->
      <!--            class="position-relative"-->
      <!--        >-->
      <!--          <base-icon width="41" height="41" v-b-modal="modalId">-->
      <!--            <icon-comment-with-number/>-->
      <!--          </base-icon>-->
      <!--          <div class="comment-count">-->
      <!--            {{ data.item.comments.length }}-->
      <!--          </div>-->
      <!--        </base-button>-->
      <!--      </div>-->
    </template>
    <!--    <pcg-comments-modal-->
    <!--        :action="() => { $bvModal.hide(modalId) }"-->
    <!--        :btn-title="$t('components.prk_modals.comments.add_comment')"-->
    <!--        :comments="data.item.comments"-->
    <!--        :current-user="currentUser"-->
    <!--        :comments-list-disabled="data.index === 3"-->
    <!--        :modal-id="modalId"-->
    <!--        :title="$t('components.prk_modals.comments.name')"-->
    <!--        @commentWasAdded="$emit('commentWasAdded', $event)"-->
    <!--    />-->
  </div>
</template>

<script>
import useHelpers from '../../../../../composables/useHelpers'
import { inject } from '@vue/composition-api'
import { mapState, mapGetters } from 'vuex'
import PrkTag from '../../../../PrkTags'

export default {
  name: 'ToVerificationContentBasicInformation',
  components: { PrkTag },
  setup () {
    const status = inject('status')
    const { uuidv4 } = useHelpers()
    return {
      uuidv4,
      status,
    }
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
    header: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      modalId: `commentsModal_${this.uuidv4()}`,
    }
  },
  computed: {
    ...mapState('syllabusFill', ['leaders']),
    ...mapGetters('environment', ['env']),
  },
  methods: {
    label (header) {
      let key = ''
      if (this.env.classCard && (header === 'subject_code' || header === 'row_2' || header === 'row_3' || header === 'row_4' || header === 'row_5' || header === 'row_12')) {
        key = `components.prk_syllabus.basic_information.${header}_class_card`
      } else {
        key = `components.prk_syllabus.basic_information.${header}`
      }
      return this.$t(key)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .tags-container {
    padding-top: 0;
    margin-bottom: 0;

    .tag {
      cursor: default;
    }
  }
}
</style>
