<template>
  <fragment>
    <pcg-modal
        :action="save"
        :btn-title="$t('general.save')"
        icon="pcg-icon-document"
        :modal-id="modalId"
        size="md"
        :title="$tc('general.educator', 2)"
        @show="show"
        @hide="$emit('hide')"
    >
    <span class="prk-fz-12">
      {{ $tc('general.educator', 2) }}
    </span>
      <coordinators-modal-search-coordinators
          :add-to-listv2="addToListv2"
          :added-educators="addedEducators"
          :educators="educators"
      />
      <coordinators-list
          :coordinators="addedEducators"
          @coordinatorWasRemoved="removeRow($event, addedEducators)"
      />
      <pcg-select
          v-if="!syllabusId.length"
          :options="saveModeOptions"
          v-model="saveMode"
          class="save-mode"
          :default-option="{ text: this.$t('general.choose'), value: null }"
          :label="this.$t('components.prk_coordinators_modal.save_mode')"
      />
      <template #button-before>
        <pcg-btn variant="additional" @click="$bvModal.hide(modalId)">
        <span class="font-weight-bold prk-fz-14">
          {{ $t('general.cancel') }}
        </span>
        </pcg-btn>
      </template>
    </pcg-modal>

    <confirm-assignment-educators @save="save"/>
  </fragment>
</template>

<script>
import CoordinatorsList from './coordinators_modal/CoordinatorsList'
import CoordinatorsModalSearchCoordinators from './coordinators_modal/CoordinatorsModalSearchCoordinators'
import ConfirmAssignmentEducators from './ConfirmAssignmentEducators'
import useList from '../../../composables/useList'
import useEducators from '../../../composables/useEducators'
import { mapState } from 'vuex'

export default {
  name: 'PrkCoordinatorsModal',
  setup (_, { root }) {
    const {
      setItems,
      addToListv2,
      removeRow,
    } = useList(root)
    const {
      mapEducators,
      getEducatorData,
    } = useEducators(root)
    return {
      addToListv2,
      getEducatorData,
      mapEducators,
      removeRow,
      setItems,
    }
  },
  components: {
    ConfirmAssignmentEducators,
    CoordinatorsModalSearchCoordinators,
    CoordinatorsList,
  },
  props: {
    modalId: { required: true, type: String },
    syllabusIds: { type: Array, default: () => ([]) },
  },
  data () {
    return {
      educators: [],
      addedEducators: [],
      saveMode: null,
      saveModeOptions: [
        { text: 'Dopisanie', value: 'add' },
        { text: 'Nadpisanie', value: 'overwrite' },
      ],
    }
  },
  computed: {
    ...mapState('syllabus', ['syllabusId']),
    ...mapState('coordinators', ['showWarning']),
    mappedSyllabusIds () {
      return this.syllabusId.length ? [this.syllabusId] : this.syllabusIds
    },
  },
  methods: {
    async getEducators (syllabusId = '') {
      const { data: educators } = await this.$api.get('educators', {
        syllabus_id: syllabusId,
      })
      return this.mapEducators(educators)
    },
    async save () {
      if ((this.saveMode !== null && this.syllabusIds.length > 0) || this.syllabusIds.length === 0) {
        const educatorData = this.getEducatorData(this.addedEducators)
        if (this.saveMode === 'overwrite' && this.showWarning) {
          this.$bvModal.show('confirm-assignment-educators')
        } else {
          await this.$api.post('syllabuses/assign_educators', {
            syllabus: {
              ids: this.mappedSyllabusIds,
              educators: educatorData,
              saveMode: this.saveMode,
            },
          })
          if (this.saveMode === 'add') {
            this.$emit('refreshCoordinators', this.addedEducators, this.mappedSyllabusIds)
            this.$bvModal.hide('coordinators-group')
            this.saveMode = null
          } else {
            this.setCoordinators(educatorData)
            this.$bvModal.hide('coordinators-group')
            this.saveMode = null
          }
        }
      } else {
        this.$toastr.i(this.$t('components.prk_coordinators_modal.save_mode_required'))
      }
    },
    setCoordinators (educatorData) {
      const ids = educatorData.filter(data => data.roles.includes('coordinator')).map(data => data.id)
      const coordinators = this.educators.filter(educator => ids.includes(educator.value)).map(educator => {
        return {
          id: educator.value,
          text: educator.text,
          syllabusIds: this.mappedSyllabusIds,
        }
      })
      this.$store.dispatch('coordinators/setCoordinators', coordinators)
    },
    async show () {
      this.educators = await this.getEducators()
      this.setItems(this.educators)

      if (!this.syllabusId.length) return

      const syllabusEducators = await this.getEducators(this.syllabusId)
      this.addedEducators.push(...syllabusEducators)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .pcg-icon-cross {
    color: $pcg-gray;
    font-size: 0.71rem;
    cursor: pointer;
  }
}

.save-mode {
  margin-bottom: 2rem;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .pcg-icon-cross {
      color: $pcg-gray;
    }
  }
}
</style>
