<template>
  <div class="matrices-edit pb-5">
    <return
        path-name="matrices_list_path"
        :modified-form="modified"
    >
      {{ $t('components.prk_matrices.return') }}
    </return>
    <matrix-inputs
        v-bind.sync="matrix"
        :name-error="$v.matrix.name.$error"
        :already-taken="errors.hasOwnProperty('name')"
        :disable-template="disableTemplate"
    />
    <matrix-base-info :matrix="matrix"/>
    <b-tabs @input="resetSelected">
      <b-tab :title="$t('components.prk_matrices.study_plan')"
             @click="subjectsActive = false">
        <matrices-study-plan
            @study-plan-removed="removeStudyPlan"
            @add-study-plan-to-list="addStudyPlan"
            @add:semester="addSemester"
            @add:all-semesters="addAllSemesters"
            @remove:semester="removeSemester"
            @remove:all-semesters="removeAllSemesters"
            :study-plans="matrix.studyPlans"
            :matrix-id="id"
            :academic-year="matrix.academicYear"/>
      </b-tab>
      <b-tab :title="$tc('general.subjects', 2)" @click="subjectsActive = true">
        <matrices-subjects
            :matrixSubjectNames="matrix.matrixSubjectNames"
            :kindId="matrix.kindId"
            :typeId="matrix.typeId"
            :skip-validation-checkbox="matrix.skipValidation"
            @skip-validation="updateSkipValidation"
            @selected-matrix-subject-names="matrixSubjectNameIds = $event"
            @course-effect-removed="removeCourseEffect"
            @add-effects="addEffects"
            @remove-all-effects="removeAllEffects"
            @subject-names-was-selected="ids = $event"
        />
      </b-tab>
      <b-tab :title="$tc('general.syllabuses', 2)" @click="subjectsActive = false">
        <matrix-syllabuses :matrixId="matrix.id" :key="matrixSyllabusesKey"/>
      </b-tab>
    </b-tabs>
    <matrix-beam
        :matrix-subject-name-ids="matrixSubjectNameIds"
        :remove-matrix-subject-names="removeMatrixSubjectNames"
        :subjects-active="subjectsActive"
        :update-matrix="updateMatrix"
        :ids="ids"
    />
    <prk-generate-syllabus :refetch="refetch" :action="generateSyllabus" @clear:refetch="refetch = false"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Return from '../../components/base_components/Return'
import MatricesStudyPlan from '../../components/matrices/MatricesStudyPlan'
import MatricesSubjects from '../../components/matrices/MatricesSubjects'
import PrkGenerateSyllabus from '../../components/matrices/modals/PrkGenerateSyllabus'
import MatrixInputs from '../../components/matrices/MatrixInputs'
import { required } from 'vuelidate/lib/validators'
import MatrixBaseInfo from '../../components/matrices/MatrixBaseInfo'
import MatrixBeam from '../../components/beams/MatrixBeam'
import useMatrices from '../../composables/useMatrices'
import MatrixSyllabuses from './MatrixSyllabuses'

const {
  mapState,
  mapActions,
} = createNamespacedHelpers('matrices')

export default {
  name: 'MatricesEdit',
  setup (props, { root }) {
    const {
      addAllSemesters,
      addEffects,
      addSemester,
      errors,
      matrix,
      matrixSubjectNameIds,
      removeAllEffects,
      removeAllSemesters,
      removeCourseEffect,
      addStudyPlan,
      getMatrix,
      removeMatrixSubjectNames,
      removeSemester,
      removeStudyPlan,
      studyPlanIds,
      subjectNameIds,
      saveMatrix,
    } = useMatrices(root)
    return {
      addAllSemesters,
      addEffects,
      addSemester,
      addStudyPlan,
      errors,
      getMatrix,
      matrix,
      matrixSubjectNameIds,
      removeAllEffects,
      removeAllSemesters,
      removeCourseEffect,
      removeMatrixSubjectNames,
      removeSemester,
      removeStudyPlan,
      saveMatrix,
      studyPlanIds,
      subjectNameIds,
    }
  },
  data () {
    return {
      id: '',
      ids: [], // for generate pdf
      subjectsActive: true,
      loading: false,
      matrixSyllabusesKey: 0,
      refetch: false,
    }
  },
  components: {
    MatrixSyllabuses,
    MatrixBeam,
    MatrixBaseInfo,
    MatrixInputs,
    PrkGenerateSyllabus,
    MatricesSubjects,
    MatricesStudyPlan,
    Return,
  },
  validations: {
    matrix: {
      syllabusId: { required },
      name: {
        required,
        isUnique () {
          return !Object.hasOwnProperty.call(this.errors, 'name')
        },
      },
    },
  },
  provide () {
    return {
      submit: this.updateMatrix,
      push: true,
    }
  },
  computed: {
    ...mapState(['modified']),
    disableTemplate () {
      return this.matrix.matrixSubjectNames.some(data => data.hasSyllabuses)
    },
  },
  watch: {
    'matrix.name' () {
      this.$v.$reset()
      this.errors.value = []
    },
  },
  created () {
    this.id = this.$route.params.id
    this.getMatrix(`matrices/${this.id}`)
    this.$store.dispatch('studyPlans/setPlan', null)
  },
  methods: {
    ...mapActions(['setModified']),
    async updateMatrix () {
      await this.saveMatrix(`matrices/${this.id}`, { $v: this.$v })
      await this.getMatrix(`matrices/${this.id}`)
      this.refetch = true
    },
    async generateSyllabus (selectedMatrixSubjectIds, semIds) {
      for (const matrixSubjectNameId of selectedMatrixSubjectIds) {
        try {
          await this.$api.post('syllabuses/generate_syllabus', {
            matrix_id: this.matrix.id,
            matrix_subject_name_id: matrixSubjectNameId,
            semester_ids: semIds,
          })
        } catch (e) {
          this.$showError(this.$getErrors(e.errors))
        }
      }
      this.$bvModal.hide('generateSyllabusModal')
      this.matrixSyllabusesKey += 1
      this.refetch = true
      await this.getMatrix(`matrices/${this.id}`)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.matrices'),
        subtitle: this.$route.query.name,
      })
    },
    resetSelected () {
      this.matrix.matrixSubjectNames.forEach(msn => (msn.selected = false))
    },
    updateSkipValidation () {
      this.matrix.skipValidation = !this.matrix.skipValidation
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  destroyed () {
    this.setModified(false)
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px;

    .pcg-table-content {
      border-radius: 15px;
    }
  }
}
</style>
