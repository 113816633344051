<template>
  <div>
    <prk-table
        :items="subItems"
        class="sub-table"
        :excluded-fields="EXCLUDED_FIELDS"
        :fields-props="{ thAttr: thAttr }"
        :show-headline="false"
        :responsive="false"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: ['id'].includes(field.key) ? '5%' : '' }"
        >
      </template>

      <template #head(name)>
        {{ $t('components.prk_syllabus.table.syllabus_card') }}
      </template>

      <template v-slot:content="{ header, data }">
        <pcg-checkbox
            v-if="header.key === 'id'"
            v-model="data.item.selected"
            @input="data.item.selected || $emit('unChecked')"
            class="pl-5"
        >
        </pcg-checkbox>
        <div v-else-if="header.key !== 'actions'" class="prk-fz-12 d-flex flex-column">
          <b-row v-if="header.key === 'status' && checkPermission(data.item.educatorIds)"
                 class="align-items-center"
          >
            <b-col cols="auto">
              <prk-statuses-select
                  :status="data.item.status"
                  :key="componentKey"
                  @input="setNewStatus(data.item.id, $event)"
              />
            </b-col>
          </b-row>
          <template v-else>
            <template v-if="header.key === 'coordinator'">
              <div>
                <div v-for="(coordinator, index) in  data.item.coordinator.split('\n')" :key="index">
                  <span class="d-flex align-items-center">
                    {{ coordinator }}
                  </span>
                </div>
              </div>
            </template>
            <template v-else-if="header.key === 'status'">
              <status :class-name="data.item.status"
                      :status="data.item.status"
              />
            </template>
            <template v-else-if="header.key === 'semesterNumber'">
              <span class="d-flex justify-content-center">{{ data.item[header.key] }}</span>
            </template>
            <span
                v-else
                class="d-flex align-items-center prk-fz-12"
                :class="{ 'pcg-main-active-color': data.item.selected }"
            >
              {{ data.item[header.key] }}
            </span>
          </template>
        </div>
        <template v-else>
          <div>
            <student-actions v-if="currentRole === 'student'"/>
            <admin-actions v-else :syllabus="data.item" :commentsCount="data.item.commentsCount"/>
          </div>
        </template>
      </template>
    </prk-table>

  </div>
</template>

<script>
import AdminActions from './syllabuses_list_details/AdminActions'
import StudentActions from './syllabuses_list_details/StudentActions'
import Status from './syllabuses_list_details/Status'
import PrkTable from '../../PrkTable'
import PrkStatusesSelect from '../../PrkStatusesSelect'
import useSyllabuses from '../../../composables/useSyllabuses'
import { detailsTableLangKey } from '../../../mixins/details_table_lang_key'
import { mapGetters } from 'vuex'
import { statuses } from '../../../helpers/statuses'

export default {
  name: 'SyllabusesListDetails',
  setup (_, { root }) {
    const { setStatus } = useSyllabuses(root)
    return { setStatus }
  },
  components: {
    PrkStatusesSelect,
    PrkTable,
    Status,
    StudentActions,
    AdminActions,
  },
  mixins: [detailsTableLangKey],
  props: {
    matrixId: { type: String, required: true },
    subItems: { type: Array, default: () => ([]) },
  },
  data () {
    return {
      componentKey: 0,
      statuses: statuses,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole', 'userId']),
    ...mapGetters('page', ['contrast']),
  },
  created () {
    this.EXCLUDED_FIELDS = [
      'effects',
      'selected',
      'matrixSubjectNameId',
      'educatorIds',
      'commentsCount',
      'educators',
      'filled',
      'matrixId',
      'acceptanceDateTime',
    ]
  },
  methods: {
    checkPermission (educatorIds) {
      if (['admin', 'employer'].includes(this.currentRole)) {
        return true
      } else {
        return !!educatorIds.includes(this.userId)
      }
    },
    async setNewStatus (id, newStatus) {
      await this.setStatus({ id, matrixId: this.matrixId, newStatus })
      this.componentKey += 1
    },
    thAttr (value, key) {
      if (key === 'id') {
        return {
          style: 'visibility: hidden',
        }
      } else {
        return {}
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.sub-table {
  ::v-deep {
    .pcg-table-content {
      background: $pcg-the-lightest-gray;
      border-radius: 0;
      tbody {
        .pcg-table-narrow-row {
          td {
            border-bottom: 1px solid $pcg-lightest-gray !important;
          }
        }
      }
    }
  }
}

::v-deep {
  .bootstrap-select .dropdown-toggle {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 2rem;

    .filter-option-inner {
      align-self: center;
    }

    .fas {
      line-height: 2.5rem;
    }
  }

  .shift {
    padding-bottom: 12px !important;
  }
}

.min-height {
  min-height: 40px;
}
</style>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/vars-contrast';

.contrast {
  .sub-table {
    ::v-deep {
      .pcg-table-content {
        background: $pcg-the-lightest-gray;
        border-radius: 0;
        tbody {
          td {
            border-bottom: 1px solid $pcg-light-gray !important;
          }
        }
      }
    }
  }
}
</style>
