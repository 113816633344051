export const filtersByRole = {
  data () {
    return {
      filters: {
        syllabuses: {
          values: [],
          roles: ['student'],
        },
        coordinators: {
          values: [],
          selected: 0,
          roles: ['admin', 'employer'],

        },
        faculty: {
          values: [],
          selected: 0,
          roles: ['student', 'admin', 'educator', 'employer'],
        },
        courseName: {
          values: [],
          selected: 0,
          roles: ['student', 'admin', 'educator', 'employer'],
        },
        subjectName: {
          values: [],
          selected: 0,
          roles: ['student', 'admin', 'educator', 'employer'],
        },
        studyProfile: {
          values: [],
          selected: 0,
          roles: ['student', 'admin', 'educator', 'employer'],
        },
        speciality: {
          values: [],
          selected: 0,
          roles: ['student', 'admin', 'educator', 'employer'],
        },
        status: {
          values: [
            {
              text: 'choose',
            },
            {
              text: 'to_fill',
              value: 'to_fill',
              class: 'to_fill',
            },
            {
              value: 'to_verification',
              text: 'to_verification',
              class: 'to_verification',
            },
            {
              value: 'to_improve',
              text: 'to_improve',
              class: 'to_improve',
            },
            {
              value: 'accepted',
              text: 'accepted',
              class: 'accepted',
            },
          ],
          roles: ['student', 'admin', 'educator', 'employer'],
        },
        semesterType: {
          values: [
            {
              text: this.$t('general.choose'),
            },
            {
              text: this.$t('general.winter'),
              value: 'winter',
            },
            {
              text: this.$t('general.summer'),
              value: 'summer',
            },
          ],
          roles: ['student', 'admin', 'educator', 'employer'],
        },
      },
    }
  },
}
