var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.filteredCategories.length)?_c('syllabus-empty-subsection',{attrs:{"section":_vm.section}}):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredCategories),function(category,index){return _c('div',{key:category.id},[(_vm.env && _vm.env.classCard)?_c('prk-table',{attrs:{"syllabus-header":true,"show-headline-index":false,"items":_vm.filterEffects(category.id),"show-sub-heading":"","excluded-fields":_vm.EXCLUDED_FIELDS,"show-headline":_vm.showHeadline && index === 0,"headline-index":_vm.headlineIndex,"disable-actions":!_vm.actions,"class-card":_vm.env.classCard == 1},scopedSlots:_vm._u([{key:"headline-text",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.section.header))]),_vm._v(" "),_c('comment-modal-button',{attrs:{"modal-id":"commentModal","comment-count":_vm.section.commentCount},on:{"input":function($event){return _vm.setComments(_vm.section, 'SyllabusSection')}}})],1)]},proxy:true},{key:"subheading-text",fn:function(){return [_c('span',{staticClass:"font-weight-bold mr-5"},[_vm._v(_vm._s(category.name))]),_vm._v(" "),_c('span',{staticClass:"font-weight-normal pcg-gray-color"},[_vm._v(_vm._s(category.description))])]},proxy:true},{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: _vm.colgroupDefinition })})})}},{key:"cell(keks)",fn:function(ref){
var keks = ref.item.keks;
return [_c('objective-effects-keks',{attrs:{"keks":keks}})]}},{key:"cell(kens)",fn:function(ref){
var kens = ref.item.kens;
return [_c('objective-effects-kens',{attrs:{"kens":kens}})]}},{key:"cell(actions)",fn:function(ref){
var effect = ref.item;
return [_c('objective-effects-actions',{attrs:{"effect":effect,"edit-effect":_vm.editEffect,"remove-effect":_vm.removeEffect}})]}}],null,true)}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }